import React from 'react';

type ProjectMetadataProps = {
  category: string;
  year: string;
  client?: string;
  role?: string;
};

export function ProjectMetadata({ category, year, client, role }: ProjectMetadataProps) {
  return (
    <div className="flex flex-col sm:flex-row justify-center items-start sm:items-center w-[90%] sm:w-[80vw] mx-auto space-y-4 sm:space-y-0">
      <div className="flex sm:flex-row items-center justify-start w-full sm:w-auto whitespace-nowrap">
        <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60">Category:</span>
        <span className="ml-2 text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]">{category}</span>
        <span className="hidden sm:block mx-4 text-[10px] sm:text-xs text-[#2E2A25]/60">•</span>
      </div>

      <div className="flex sm:flex-row items-center justify-start w-full sm:w-auto whitespace-nowrap">
        <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60">Year:</span>
        <span className="ml-2 text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]">{year}</span>
        {(client || role) && (
          <span className="hidden sm:block mx-4 text-[10px] sm:text-xs text-[#2E2A25]/60">•</span>
        )}
      </div>

      {client && (
        <div className="flex sm:flex-row items-center justify-start w-full sm:w-auto whitespace-nowrap">
          <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60">Client:</span>
          <span className="ml-2 text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]">{client}</span>
          {role && (
            <span className="hidden sm:block mx-4 text-[10px] sm:text-xs text-[#2E2A25]/60">•</span>
          )}
        </div>
      )}

      {role && (
        <div className="flex sm:flex-row items-center justify-start w-full sm:w-auto whitespace-nowrap">
          <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60">Role:</span>
          <span className="ml-2 text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]">{role}</span>
        </div>
      )}
    </div>
  );
}