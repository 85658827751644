import React from 'react';

type SocialLinksProps = {
  isLightPage: boolean;
};

export function SocialLinks({ isLightPage }: SocialLinksProps) {
  const socialLinks = [
    { label: 'INSTAGRAM', href: 'https://www.instagram.com/lewmit/' },
    { label: 'LINKEDIN', href: 'https://www.linkedin.com/in/lewis-mitchell-producer/' },
    { label: 'IMDB', href: 'https://www.imdb.com/name/nm14065351/' },
  ];

  return (
    <ul className="space-y-0.5 ml-4 sm:ml-[20%]">
      {socialLinks.map((link) => (
        <li key={link.label}>
          <a
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-[10px] sm:text-xs uppercase tracking-[-0.03em] transition-opacity duration-300 ${
              isLightPage
                ? 'text-white hover:opacity-60'
                : 'text-navText hover:opacity-60'
            }`}
          >
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  );
}