import React from 'react';
import { motion } from 'framer-motion';
import { LoadingAnimation } from './LoadingAnimation';

export function LoadingScreen() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-cream z-50 flex flex-col items-center justify-center"
    >
      <LoadingAnimation />
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        className="mt-8 text-[10px] tracking-[0.2em] uppercase text-[#2E2A25]/60"
      >
        Loading
      </motion.p>
    </motion.div>
  );
}