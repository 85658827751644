import React from 'react';
import { Link } from 'react-router-dom';

type NavLinksProps = {
  activeSection: string;
  setActiveSection: (section: string) => void;
  closeMenu: () => void;
  isLightPage: boolean;
};

export function NavLinks({ activeSection, setActiveSection, closeMenu, isLightPage }: NavLinksProps) {
  const menuItems = [
    { id: 'projects', label: 'The Work', path: '/projects' },
    { id: 'about', label: 'About', path: '/about' },
    { id: 'contact', label: 'Contact', path: '/contact' },
  ];

  return (
    <ul className="space-y-3 ml-[20%]">
      {menuItems.map((item) => (
        <li key={item.id}>
          <Link
            to={item.path}
            onClick={() => {
              setActiveSection(item.id);
              closeMenu();
            }}
            className={`text-xl sm:text-2xl md:text-3xl lg:text-4xl uppercase font-medium tracking-[-0.03em] transition-opacity duration-300 ${
              isLightPage
                ? 'text-white hover:opacity-60'
                : 'text-navText hover:opacity-60'
            }`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}