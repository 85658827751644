import { Project } from '../../types/project';

export const newMonday: Project = {
  id: 'new-monday',
  title: "The New Monday",
  category: "Event",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/THE%20NEW%20MONDAY%20-%20LEEDS%20-%20YORKSHIRE%20-%20CREATIVE%20INDUSTRY%20-%20SCREEN%20INDUSTRY%20-%20COMMUNITY%20-%20NETWORKING%20EVENT%20-%20CHANNEL%204%20-%20LEEDS%20CITY%20COUNCIL%20-%20WYCA%20-%20LEWIS%20MITCHELL%20-%202.jpeg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/THE%20NEW%20MONDAY%20-%20LEEDS%20-%20YORKSHIRE%20-%20CREATIVE%20INDUSTRY%20-%20SCREEN%20INDUSTRY%20-%20COMMUNITY%20-%20NETWORKING%20EVENT%20-%20CHANNEL%204%20-%20LEEDS%20CITY%20COUNCIL%20-%20WYCA%20-%20LEWIS%20MITCHELL%20-%201.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/THE%20NEW%20MONDAY%20-%20LEEDS%20-%20YORKSHIRE%20-%20CREATIVE%20INDUSTRY%20-%20SCREEN%20INDUSTRY%20-%20COMMUNITY%20-%20NETWORKING%20EVENT%20-%20CHANNEL%204%20-%20LEEDS%20CITY%20COUNCIL%20-%20WYCA%20-%20LEWIS%20MITCHELL.jpeg"
  ],
  year: "2021",
  description: "The New Monday networking event. Bringing together the region's creative community, the initiative was sponsored by Channel 4, Leeds City Council and West Yorkshire Combined Authority. Our network grew in excess of 2,000 individuals and an average event attendance of 120 people. Impact reports found significant collaboration and work was generated as a result of the events.",
  role: "Co-founder",
  credits: [
    "Sponsored by: Channel 4, Leeds City Council & West Yorkshire Combined Authority",
    "Partnered with: Tell Studio, Fawkes Digital, We Are the Allies, & Department UK"
  ],
  links: [
    { label: "More info", url: "https://thenewmonday.co.uk/" }
  ]
};