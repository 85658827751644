import { Project } from '../../types/project';

export const witchsDaughter: Project = {
  id: 'witchs-daughter',
  title: "The Witch's Daughter",
  category: "Period Drama",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-EMMA%20SWINTON-EMILY%20CARLTON-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER.jpg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER-LEEDS-PERIOD-DRAMA.jpg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER-LEEDS-PERIOD-DRAMA-2.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER-LEEDS-PERIOD-DRAMA-1.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER-LEEDS-PERIOD-DRAMA-4.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TWD-THE-WITCHS-DAUGHTER-POSTER-JO-HARTLEY-BURN-GORMAN-LEWIS-MITCHELL-PRODUCER-LEEDS-PERIOD-DRAMA-3.jpg"
  ],
  year: "2023",
  description: "BFI funded. nominated for best British director and best supporting actor, and winner of best costume design at The British Short Film Awards.\n\nIn 1612 Lancashire, Nursemaid Sarra is used to entrap Jennet, a child from a family of accused 'witches'. But when Sarra's fear of the girl turns to love, she's compelled to betray her Master in England's most infamous witch trial.",
  role: "Co-producer",
  credits: [
    "Sarra: Jo Hartley",
    "Jennet: Esme Violet Whalley",
    "Roger: Burn Gorman",
    "Katherine: Caroline Chesworth",
    "Maid: Chelsea Edge",
    "Elizabeth Southerns: Maureen Roberts",
    "Directed by: Emma Swinton",
    "Written by: Emma Swinton & Emily Carlton",
    "Produced by: Diva Rodriguez",
    "Co-producer: Lewis Mitchell",
    "Director of Photography: Matthew Gillan",
    "First Assistant Camera: Ben McFarlane",
    "Second Assistant Camera: Owen Seabrook & Beanie Baker",
    "Camera Assistant: Keely Pickard, Will Newnham & Luke Shires",
    "First Assistant Director: Louisa Rose Mackleston",
    "Third Assistant Director: Katie Burnside",
    "Floor Runners: Sean Ramsden, Meaghan Hutchinson, Caitlin Garret, Beau Harben & Evelyn Lambert",
    "Casting Director: Claire Bleasdale",
    "Production Assistants: Blake Butterworth & Nayan Sharma",
    "Costume Designer: Karen Glover",
    "Hair and Makeup Designer: Eleanor Hart",
    "Hair and Makeup Assistant: Jessica Liddle & Hamaas Mahmood",
    "Gaffer: Jamie Burr",
    "Electrician: Luke Hubbard",
    "Lighting Assistant: Edward Hinkles & Detroya Smith",
    "Production Designer: John Naylor",
    "Art Director: Jonny Maddox",
    "Art Department Assistant: Sarah Wigham",
    "Rigger: Sam Holyfield",
    "Script Consultant: Sarah Golding",
    "Stills Photographer: Lisa Stonehouse",
    "Editor: Sam Cobb",
    "Music composed by: Hollie Buhagiar",
    "Instrumentalist: Bella Ava Georgiou",
    "Production Sound Mixer: Robert Wingfield, Chris Lawton & Jack Welsby",
    "Sound Assistant/Boom: Jacob Marvin & Jacob Perrin",
    "Jennet Chaperone: Gemma Whalley",
    "Wellbeing Facilitator: Mark Knowles",
    "Picture Post and Color Grade by: Nielsan Bohl",
    "Sound Post by: Michalianna Theofanopoulou",
    "Visual Effects by: Liam J. M. Wilson, Jody McAlavey & Russell Agro",
    "Associate Producer: Emily Hutchings",
    "Associate Producer: Maoliosa Kelly",
    "BFI Network - Executive Producer: Ben Taylor",
    "BFI Network - Project Coordinator: Niamh Coogan"
  ],
  links: [
    { label: "IMDB", url: "https://www.imdb.com/title/tt22988316/?ref_=nm_flmg_job_1_cdt_t_4" },
    { label: "Trailer", url: "https://youtu.be/9doZuCs3ODE" }
  ]
};