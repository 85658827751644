import React from 'react';
import { motion } from 'framer-motion';
import { FilmGrain } from '../effects/FilmGrain';

export function CircularArrow() {
  const circleFillVariants = {
    initial: { 
      opacity: 0
    },
    animate: { 
      opacity: 0.4,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      transition: {
        duration: 0.15,
        ease: "easeIn"
      }
    }
  };

  const circleBorderVariants = {
    initial: { 
      opacity: 0
    },
    animate: { 
      opacity: 0.8,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      transition: {
        duration: 0.25,
        ease: "easeIn"
      }
    }
  };

  const arrowVariants = {
    initial: { 
      opacity: 0,
      pathLength: 0,
      rotate: 0
    },
    animate: { 
      opacity: 1,
      pathLength: 1,
      rotate: 45,
      transition: {
        opacity: {
          duration: 0.2,
          ease: "easeOut"
        },
        pathLength: {
          duration: 0.2,
          ease: "easeOut"
        },
        rotate: {
          duration: 0.3,
          ease: "easeOut",
          delay: 0.1
        }
      }
    },
    exit: { 
      opacity: 0,
      pathLength: 0,
      rotate: 0,
      transition: {
        opacity: {
          duration: 0.3,
          ease: "easeIn",
          delay: 0.1
        },
        pathLength: {
          duration: 0.3,
          ease: "easeIn",
          delay: 0.1
        },
        rotate: {
          duration: 0.2,
          ease: "easeIn"
        }
      }
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className="absolute inset-0 flex items-center justify-center pointer-events-none"
    >
      <div className="relative w-12 h-12">
        <motion.div 
          variants={circleFillVariants}
          className="absolute inset-0 rounded-full bg-[#2D2A25] backdrop-blur-[4px]"
        >
          <FilmGrain opacity={0.35} />
        </motion.div>
        <motion.div 
          variants={circleBorderVariants}
          className="absolute inset-0 rounded-full border border-[#D9C5A6]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <motion.svg 
            width="20" 
            height="20" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            variants={arrowVariants}
          >
            <motion.path 
              d="M7 17L17 7M17 7H7M17 7V17" 
              stroke="#D9C5A6"
              strokeWidth="1" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </motion.svg>
        </div>
      </div>
    </motion.div>
  );
}