import React from 'react';
import { motion } from 'framer-motion';

export function LoadingAnimation() {
  const circleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { 
      scale: 1, 
      opacity: 1,
      transition: {
        duration: 1.2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  };

  const innerCircleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { 
      scale: 1, 
      opacity: 1,
      transition: {
        duration: 1.2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
        delay: 0.1
      }
    }
  };

  const lineVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
        delay: 0.2
      }
    }
  };

  return (
    <div className="relative w-24 h-24">
      <motion.div
        variants={circleVariants}
        initial="initial"
        animate="animate"
        className="absolute inset-0 border border-[#2E2A25]/20 rounded-full"
      />
      <motion.div
        variants={innerCircleVariants}
        initial="initial"
        animate="animate"
        className="absolute inset-2 border border-[#2E2A25]/30 rounded-full"
      />
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M50 20 L50 80 M20 50 L80 50"
          stroke="#2E2A25"
          strokeWidth="0.5"
          strokeLinecap="round"
          variants={lineVariants}
          initial="initial"
          animate="animate"
        />
      </svg>
    </div>
  );
}