import { Project } from '../types/project';
import { cutShort } from './projects/cutShort';
import { witchsDaughter } from './projects/witchsDaughter';
import { takeNote } from './projects/takeNote';
import { mtjav } from './projects/mtjav';
import { newMonday } from './projects/newMonday';
import { marooned } from './projects/marooned';
import { whittard } from './projects/whittard';

// Export projects in desired order
export const projects: Project[] = [
  cutShort,
  witchsDaughter,
  takeNote,
  mtjav,
  newMonday,
  marooned,
  whittard
];