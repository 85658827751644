import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLinks } from './nav/NavLinks';
import { SocialLinks } from './nav/SocialLinks';
import { InfoSection } from './nav/InfoSection';
import { useScrollLock } from '../utils/hooks/useScrollLock';
import { ROUTES } from '../utils/constants';

type NavigationProps = {
  activeSection: string;
  setActiveSection: (section: string) => void;
};

export function Navigation({ activeSection, setActiveSection }: NavigationProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === ROUTES.HOME;
  const isLightPage = location.pathname === ROUTES.ABOUT || 
    location.pathname === ROUTES.CONTACT || 
    location.pathname.startsWith('/projects/');

  useScrollLock(isMenuOpen);

  const handleLogoClick = () => {
    if (!isHomePage) {
      setActiveSection('home');
    }
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 p-8 flex justify-between items-center z-50">
        <Link
          to={ROUTES.HOME}
          onClick={handleLogoClick}
          className={`hero-title text-sm transition-all duration-300 ${
            isMenuOpen 
              ? isLightPage
                ? 'text-cream hover:text-cream/80'
                : 'text-navText hover:text-navText-hover'
              : isLightPage
                ? 'text-navText hover:text-navText-hover'
                : 'text-cream hover:text-cream/80'
          } ${
            !isHomePage || isMenuOpen
              ? 'opacity-100' 
              : 'opacity-0 pointer-events-none'
          }`}
        >
          LEWMIT
        </Link>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={`transition-colors duration-300 ${
            isMenuOpen 
              ? isLightPage
                ? 'text-cream hover:text-cream/80'
                : 'text-navText hover:text-navText-hover'
              : isLightPage
                ? 'text-navText hover:text-navText-hover'
                : 'text-cream hover:text-cream/80'
          }`}
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        >
          <span className="text-sm uppercase tracking-wider flex items-center">
            Menu
            <span className="relative ml-1 text-lg leading-none -translate-y-0.5 w-5 h-5 inline-flex items-center justify-center">
              <span 
                className={`absolute transition-all duration-300 ${
                  isMenuOpen ? 'rotate-45 scale-[1.1]' : 'rotate-0 scale-100'
                }`}
              >
                +
              </span>
            </span>
          </span>
        </button>
      </div>

      <nav 
        className={`fixed inset-x-0 top-0 h-[75vh] z-40 transform transition-all duration-500 ease-in-out ${
          isMenuOpen 
            ? isLightPage
              ? 'bg-[#0E0E0E] translate-y-0'
              : 'bg-cream translate-y-0'
            : '-translate-y-full'
        }`}
        aria-hidden={!isMenuOpen}
      >
        <div className="h-full flex flex-col justify-start pt-40 max-w-7xl mx-auto px-8">
          <div className="flex">
            <InfoSection isLightPage={isLightPage} />
            <SocialLinks isLightPage={isLightPage} />
            <NavLinks 
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              closeMenu={() => setIsMenuOpen(false)}
              isLightPage={isLightPage}
            />
          </div>
        </div>
      </nav>
    </>
  );
}