import React from 'react';
import { motion } from 'framer-motion';
import { CREDITS_ROLL_EASE } from '../../utils/constants/animations';

export function RollingContactBanner() {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const text = "GET IN TOUCH";
  
  React.useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth / 2);
    }
  }, []);

  const duplicatedText = Array(8).fill(text);
  
  return (
    <div className="w-screen relative left-[50%] right-[50%] -ml-[50vw] -mr-[50vw] overflow-hidden bg-[#2E2A25] py-4">
      <motion.div
        ref={containerRef}
        animate={{
          x: ["0%", `-${containerWidth}px`],
        }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 20,
            ease: CREDITS_ROLL_EASE,
          },
        }}
        className="flex whitespace-nowrap"
      >
        {duplicatedText.map((text, index) => (
          <React.Fragment key={`${text}-${index}`}>
            <span className="text-cream text-2xl md:text-3xl lg:text-4xl uppercase font-light tracking-[-0.03em] mx-2 hero-title">
              {text}
            </span>
            <span className="text-cream text-2xl md:text-3xl lg:text-4xl uppercase font-light tracking-[-0.03em] mx-3 hero-title">
              •
            </span>
          </React.Fragment>
        ))}
      </motion.div>
    </div>
  );
}