import React from 'react';
import { ContactMetadata } from './contact/ContactMetadata';
import { RollingContactBanner } from './contact/RollingContactBanner';
import { TopRollingContactBanner } from './contact/TopRollingContactBanner';

export function Contact() {
  return (
    <div className="page-fixed bg-cream">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full">
        <div className="text-center mb-8">
          <h2 className="text-sm uppercase tracking-wider text-[#2E2A25] text-center">CONTACT</h2>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0">
          <ContactMetadata label="Email" value="lewis.c.mitchell@gmail.com" />
          <span className="hidden sm:block text-xs text-[#2E2A25]/60 mx-4">•</span>
          <ContactMetadata label="Location" value="United Kingdom/New Zealand" />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0">
        <TopRollingContactBanner />
        <RollingContactBanner />
      </div>
    </div>
  );
}