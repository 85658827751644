import { Project } from '../../types/project';

export const cutShort: Project = {
  id: 'cut-short',
  title: "Cut Short",
  category: "Impact Campaign",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/4.%20Cut%20Short%20Poster.jpg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/Cut%20Short-IMPACT%20DOCUMENTARY-KNIFE%20CRIME%20-%20UK%20-%20DIRECTOR%20OWEN%20SEABROOK%20-%20PRODUCED%20BY%20LEWIS%20MITCHELL.jpg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/CUT%20SHORT%20IMPACT%20DOCUMENTARY%20FROM%20PRODUCTION%20COMPANY%20TELL%20STUDIO%20-%20CREATIVE%20PRODUCER%20LEWIS%20MITCHELL.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/CUT%20SHORT%20IMPACT%20DOCUMENTARY%20FROM%20PRODUCTION%20COMPANY%20TELL%20STUDIO%20-%20CREATIVE%20PRODUCER%20LEWIS%20MITCHELL%202.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/LAUNCH%20-%20BBC%20NEWS%20-%20Cut-Short-Fighting-Against-Knives-in-the-North-Impact-Documentary-From-Tell-Studio-Directed-by-Owen-Seabrook-and-Produced-by-Lewis-Mitchell-and-Maizie-Brown-Frame.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Cut%20Short%20crew%20at%20the%20launch%20of%20Cut%20Short.%20Courtesy%20of%20Simon%20and%20Simon%20Photography.jpg"
  ],
  year: "2023",
  description: "An award-winning documentary and social impact campaign addressing serious youth violence in the UK. It was distributed alongside a workshop resource to over 250 organisations (including 20 regional Violence Reduction Units). As of Feb 2024, it had reached an estimated 150,000 young people nationwide.",
  role: "Producer",
  credits: [
    "Starring: Sarah Lloyd, Vilson Duarte-Dong, Lucie Milner, Matt 'Blessed' Stevens, Luke & Matthew Norford",
    "Directed by: Owen Seabrook",
    "Produced by: Lewis Mitchell & Maizie Brown",
    "DoP: Declan Creffield",
    "1st AC: Laura Clark",
    "Sound Recordists: Chris Lipscombe, Joe Allies & Philip Shacklady",
    "Unit Stills: Lisa Stonehouse",
    "BTS Stills: Owais Manzoor",
    "BTS Video: James Ivanov & Daniel Tremble",
    "Editor: Owen Seabrook",
    "Colourist: Paul Handley & Summer Vasare Piscikaite",
    "Graphic Designer: Hattie Barnes",
    "Composer: George Bloomfield",
    "Sound Designer: Philip Shacklady",
    "Dubbing Engineer: Will Cart"
  ],
  links: [
    { label: "Trailer & more information", url: "https://tell.studio/cutshort/" },
    { label: "West Yorkshire Police on Cut Short", url: "https://youtu.be/_qEaNDZnHf0?si=12Bob9xqwH14OWqH" }
  ]
};