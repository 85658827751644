import React from 'react';
import { ProjectCard } from './ProjectCard';
import { useProjectStore } from '../../store/projectStore';

export function ProjectGrid() {
  const { projects } = useProjectStore();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 max-w-5xl mx-auto">
      {projects.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  );
}