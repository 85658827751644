import { Project } from '../../types/project';

export const takeNote: Project = {
  id: 'take-note',
  title: "Take Note",
  category: "Commercial",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR.jpeg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR-1.jpeg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR-2.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR-3.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR-4.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/TAKE-NOTE-YOUTH-MUSIC-AWARDS-2023-INTRODUCTION-VIDEO-CAMPAIGN-MUSIC-CHARITY-LEWIS-MITCHELL-PRODUCER-DIRECTOR-5.jpg"
  ],
  year: "2023",
  description: "At a time of increasing inequalities across line-ups – from festivals to awards ceremonies – Youth Music are calling on the music industries to TAKE NOTE and champion a more diverse, more inclusive and more creative future of music. Here's the opening film from the Youth Music Awards 2023 in association with Hal Leonard Europe. #YMAs23",
  role: "Producer Director",
  credits: [
    "Client: Youth Music",
    "Starring:Hattie Barnes, Andrew Cowan, Liz Taylor Johnson, Kelly, Kenzie, KG, Chelsea De Lacq Le Montais, Rio Jose Lopez, Meduulla, Ollie Pinder, Fliss Scott, T.T &Drew O'Brien",
    "Directed by: Owen Seabrook & Lewis Mitchell",
    "Produced by: Owen Seabrook & Lewis Mitchell",
    "Words by: Meduulla",
    "Music by: Richard Carter",
    "Bars by: KG",
    "Camera Assistant: Will Newnham",
    "Dubbing Engineer: Will Cart"
  ],
  links: [
    { label: "Youth Music Awards", url: "https://youthmusic.org.uk/awards" },
    { label: "Watch Here", url: "https://youtu.be/l2yfamOWPJc?si=-pggKgTit_-RZCqa" }
  ]
};