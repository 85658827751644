import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useProjectStore } from '../../store/projectStore';
import { ProjectHeader } from './ProjectHeader';
import { RollingCreditsBanner } from './RollingCreditsBanner';
import { ProjectMetadata } from './ProjectMetadata';

export function ProjectDetail() {
  const { id } = useParams();
  const { projects } = useProjectStore();
  const project = projects.find(p => p.id === id);

  if (!project) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#7CB1CF] text-[#2E2A25]">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Project not found</h2>
          <Link to="/projects" className="text-xs uppercase tracking-[-0.03em] text-[#2E2A25] hover:opacity-60 transition-opacity duration-300">
            Back to Projects
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#7CB1CF] text-[#2E2A25]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 pb-32 sm:pb-12 flex flex-col">
        <div className="flex flex-col items-center">
          <ProjectHeader title={project.title} description={project.description} />
          <div className="mt-4 mb-12">
            <ProjectMetadata
              category={project.category}
              year={project.year}
              client={project.client}
              role={project.role}
            />
          </div>
          {project.poster && (
            <div className="flex flex-col items-center mt-6">
              <div className="relative w-[70vw] sm:w-auto sm:h-[60vh] flex justify-center">
                <img 
                  src={project.poster} 
                  alt={`${project.title} Poster`}
                  className="w-full sm:w-auto sm:h-full object-contain"
                />
              </div>
              {project.links && project.links.length > 0 && (
                <div className="flex justify-center space-x-6 mt-4">
                  {project.links.map((link, index) => (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] hover:opacity-60 transition-opacity duration-300"
                    >
                      {link.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          )}
          
          {project.images && project.images.length > 0 && (
            <div className="w-[70vw] sm:w-[60vw] mt-24">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {project.images.map((image, index) => (
                  <div key={index} className="relative aspect-video">
                    <img
                      src={image}
                      alt={`${project.title} - Image ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {project.credits && project.credits.length > 0 && (
          <div className="mt-24">
            <div className="flex justify-center">
              <h2 className="text-[10px] sm:text-xs md:text-sm uppercase tracking-wider text-[#2E2A25] text-center mb-6">CREDITS</h2>
            </div>
            <RollingCreditsBanner credits={project.credits} />
          </div>
        )}

        <div className="mt-24 pb-12 flex justify-center">
          <Link 
            to="/projects"
            className="flex items-center text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] hover:opacity-60 transition-opacity duration-300"
          >
            <ArrowLeft className="w-3 h-3 mr-2" />
            Back
          </Link>
        </div>
      </div>
    </div>
  );
}