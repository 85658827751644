import React from 'react';
import { Camera, Film, Award } from 'lucide-react';

export function About() {
  return (
    <div className="h-full overflow-y-auto bg-cream">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:pl-[2%] md:pr-[10%] pt-[10vh] md:pt-[20vh] pb-24 sm:pb-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="h-[50vh] md:h-[calc(100vh-20vh)] md:sticky md:top-[20vh] flex items-center md:items-end">
            <img 
              src="https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/LEWIS%20MITCHELL%20AND%20GEORGE%20BLOOMFIELD%20-%20MTJAV%20DOCUMENTARY%20MUSIC%20VIDEO%20SHORT%20FILM.png" 
              alt="Lewis Mitchell"
              className="w-full object-contain object-bottom"
            />
          </div>
          <div className="px-4 sm:px-0">
            <div className="flex flex-col sm:flex-row sm:items-end sm:space-x-4 mb-8 md:mb-12">
              <h2 className="hero-title text-2xl sm:text-3xl md:text-4xl font-bold text-[#2E2A25] whitespace-nowrap">ABOUT ME</h2>
              <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60 mt-2 sm:mt-0 sm:mb-1">* the bald one</span>
            </div>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">I am a creative producer with over 5 years of experience in impact-driven documentary and narrative filmmaking. As co-founder of Tell Studio, I specialised in producing documentaries that drive social change, collaborating with major organisations including Youth Music, NHS, Ralph Lauren, BBC, and War Child.</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">Highlights of my work include:</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• Producer of "Cut Short" - An award-winning documentary and impact campaign to tackle knife crime in the UK, distributed nationwide alongside intervention resources reaching hundreds of thousands of young people through over 250 organisations.</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• Co-producer of "The Witch's Daughter": A BFI-funded short film starring internationally recognised talent: Burn Gorman (Game of Thrones, The Dark Knight Rises) and Jo Hartley (This Is England, Dead Man's Shoes). Screened at BAFTA qualifying festivals and winner of Best Costume Design at British Short Film Awards.</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• Secured funding from: The British Film Institute, Innovate UK (UKRI) Creative Catalyst, UK Arts & Humanities Research Council, The Prince's Trust, Leeds City Council, and Youth Music.</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• Various experience in production roles on high-end projects like Netflix's "Encounters" (Boardwalk Pictures/Vice Studios/Amblin Television).</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• Founding member of "The New Monday" networking event. Bringing together the region's creative community, and sponsored by Channel 4, Leeds City Council and West Yorkshire Combined Authority.</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-2">Industry Development Programmes:</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-2">• Screen Yorkshire & Screen Industries Growth Network - Flex (2021)</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-2">• British Film Institute & Film Hub North - Creative Producer Lab (2022)</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] mb-8">• British Library - Get Ready for Business Growth (2023)</p>
            <p className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25] pb-12 md:pb-24">I combine creative vision with strong project management to deliver compelling content that creates meaningful social impact. I'm always on the look out for opportunities to produce purpose-driven films that engage audiences and inspire change.</p>
          </div>
        </div>
      </div>
    </div>
  );
}