import { Project } from '../../types/project';

export const whittard: Project = {
  id: 'whittard',
  title: "Whittard of Chelsea",
  category: "Commercial",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-POSTER.jpg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-MAIN.jpg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-1.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-2.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-3.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/The%20Home%20of%20Great%20Taste%20-%20Whittard%20of%20Chelsea%20-%20Christmas%20Ad%202024%20-%20LEWIS%20MITCHELL%20-%20PRODUCER%20-%20POWERHOUSE%20-%20PRODUCTION%20COMPANY-4.jpeg"
  ],
  year: "2024",
  description: "Full visual content production for Whittard Of Chelsea's 2024 Christmas collection. Celebrating the season's iconic flavours, aromas, and indulgences, designed to transport audiences back to cherished memories and invoke a sense of nostalgia. \"With a lot to get through in a short time, Lewis brought together a stellar team, managed the set build, talent, styling, schedule and helped the client understand the vision in the run up to the shoot itself. During the shoot he made sure everyone kept on time and on budget. In fact he came in under budget. I can't recommend him highly enough.\" - Rebecca Jones, Client Services Director at Powerhouse",
  role: "Producer",
  credits: [
    "Starring: Leela Tikadar, Dante Selleri, Shabana (Sassy) Laher, Reece Raymond & Anna-Elise Clain",
    "Director: Tony Lendill",
    "Producer: Lewis Mitchell",
    "Cinematographer: Luke Whitely",
    "First Camera Assistant: Sam Hotson",
    "Second Camera Assistant: Tom Walker",
    "Production assistant: Max Wailes",
    "Runner: Sparkle Menzies",
    "Food Stylist: Samantha Wilson",
    "Set Stylist: Milly Hewitt",
    "Assistant Set Stylist: John Driscoll & Eve Miller",
    "Fashion Stylist: Ona Greenwood"
  ],
  links: [
    { label: "Watch Here", url: "https://youtu.be/1QaXqSjdCbA?si=eNkldxwSQyPkeV89" }
  ]
};