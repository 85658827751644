import React from 'react';
import { ProjectGrid } from './projects/ProjectGrid';
import { useImagePreload } from '../utils/hooks/useImagePreload';

export function Projects() {
  useImagePreload();

  return (
    <div className="h-full overflow-y-auto bg-[#0E0E0E]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8 sm:py-12 flex flex-col">
        <div className="flex-1 flex items-center justify-center mb-8 sm:mb-12 mt-16 sm:mt-10">
          <h2 className="hero-title text-2xl sm:text-3xl md:text-4xl font-bold text-white whitespace-nowrap">SELECTED WORK</h2>
        </div>
        <ProjectGrid />
        {/* Added bottom padding for mobile consistency */}
        <div className="h-24 sm:h-0" />
      </div>
    </div>
  );
}