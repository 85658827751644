import React from 'react';

type ContactMetadataProps = {
  label: string;
  value: string;
};

export function ContactMetadata({ label, value }: ContactMetadataProps) {
  return (
    <div className="flex items-center">
      <span className="text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]/60">{label}:</span>
      <span className="ml-2 text-[10px] sm:text-xs uppercase tracking-[-0.03em] text-[#2E2A25]">{value}</span>
    </div>
  );
}