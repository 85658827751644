import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Hero } from './components/Hero';
import { Projects } from './components/Projects';
import { ProjectDetail } from './components/projects/ProjectDetail';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Navigation } from './components/Navigation';
import { PageTransition } from './components/transitions/PageTransition';
import { HeroTransition } from './components/transitions/HeroTransition';
import { LoadingScreen } from './components/loading/LoadingScreen';
import { useLoading } from './hooks/useLoading';

export function App() {
  const location = useLocation();
  const [activeSection, setActiveSection] = React.useState('home');
  const isProjectDetail = location.pathname.startsWith('/projects/');
  const isLoading = useLoading();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={`h-screen ${isProjectDetail ? 'overflow-y-auto' : 'overflow-hidden'} bg-black`}>
      <Navigation activeSection={activeSection} setActiveSection={setActiveSection} />
      <div className="h-full">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={
              <HeroTransition>
                <Hero setActiveSection={setActiveSection} />
              </HeroTransition>
            } />
            <Route path="/projects" element={
              <PageTransition>
                <Projects />
              </PageTransition>
            } />
            <Route path="/projects/:id" element={
              <PageTransition>
                <ProjectDetail />
              </PageTransition>
            } />
            <Route path="/about" element={
              <PageTransition>
                <About />
              </PageTransition>
            } />
            <Route path="/contact" element={
              <PageTransition>
                <Contact />
              </PageTransition>
            } />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}