import React from 'react';

type InfoSectionProps = {
  isLightPage: boolean;
};

export function InfoSection({ isLightPage }: InfoSectionProps) {
  const infoItems = [
    { label: 'CREATIVE PRODUCER' },
    { label: 'UK/NZ' },
  ];

  return (
    <div className="hidden sm:block">
      <ul className="space-y-0.5">
        {infoItems.map((item) => (
          <li key={item.label}>
            <span className={`text-xs uppercase tracking-[-0.03em] ${
              isLightPage ? 'text-white' : 'text-navText'
            }`}>
              {item.label}
            </span>
          </li>
        ))}
      </ul>
      <div className="mt-8 space-y-0.5">
        <span className={`block text-xs uppercase tracking-[-0.03em] ${
          isLightPage ? 'text-white' : 'text-navText'
        }`}>
          WEBSITE BUILT BY
        </span>
        <span className={`block text-xs uppercase tracking-[-0.03em] ${
          isLightPage ? 'text-white' : 'text-navText'
        }`}>
          LEWMIT ©{new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
}