import React from 'react';

export function FooterSocialLinks() {
  const socialLinks = [
    { label: 'INSTAGRAM', href: 'https://www.instagram.com/lewmit/', position: 'left' },
    { label: 'IMDB', href: 'https://www.imdb.com/name/nm14065351/', position: 'center' },
    { label: 'LINKEDIN', href: 'https://www.linkedin.com/in/lewis-mitchell-producer/', position: 'right' },
  ];

  return (
    <div className="absolute bottom-8 right-0 px-8 md:px-16 lg:px-24 z-20 w-1/2">
      <div className="relative flex justify-between items-center w-full">
        {socialLinks.map((link) => (
          <a
            key={link.label}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs uppercase tracking-[-0.03em] text-cream transition-opacity duration-300 opacity-100 hover:opacity-60"
          >
            {link.label}
          </a>
        ))}
      </div>
    </div>
  );
}