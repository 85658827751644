import React from 'react';

type ProjectHeaderProps = {
  title: string;
  description: string;
};

export function ProjectHeader({ title, description }: ProjectHeaderProps) {
  return (
    <div className="flex-1 flex items-center justify-center mb-12 mt-10">
      <div className="w-[90%] sm:w-[80vw] md:w-[70vw] lg:w-[60vw] flex flex-col items-center">
        <h1 className="hero-title text-2xl sm:text-3xl md:text-4xl font-bold text-[#E8D6E7] uppercase mb-8 sm:mb-12 text-center w-[90%] sm:w-auto">{title}</h1>
        <p className="text-[10px] sm:text-xs md:text-sm uppercase tracking-wider text-[#2E2A25] text-center mb-2 w-full">{description}</p>
      </div>
    </div>
  );
}