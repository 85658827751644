import React from 'react';
import { motion } from 'framer-motion';

type FilmGrainProps = {
  opacity?: number;
};

export function FilmGrain({ opacity = 0.2 }: FilmGrainProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity }}
      className="absolute inset-0 pointer-events-none mix-blend-overlay"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.5' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
      }}
    />
  );
}