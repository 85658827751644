import { Project } from '../../types/project';

export const mtjav: Project = {
  id: 'MTJAV',
  title: "More Than Just a Van",
  category: "Documentary",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL.jpg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/FILM-MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL-3.jpeg",
  images: [
    "https://github.com/lewmit/Website/blob/main/FILM-MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL-5.jpeg?raw=true",
    "https://github.com/lewmit/Website/blob/main/FILM-MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL-1.jpeg?raw=true",
    "https://github.com/lewmit/Website/blob/main/FILM-MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL-4.jpeg?raw=true",
    "https://github.com/lewmit/Website/blob/main/FILM-MORE-THAN-JUST-A-VAN-MUSIC-VIDEO-DOCUMENTARY-YOUTH-MUSIC-LEEDS-CITY-COUNCIL-2.jpeg?raw=true"
  ],
  year: "2024",
  description: "Funded by Youth Music and Leeds Inspired, MTJAV is a music video meets documentary exploring creativity and loss. George Bloomfield, a recording musician, comes to terms with the loss of his best friend, Ed. Traveling across the country, he meets Ed's family in an attempt to make sense of their loss.",
  role: "Producer Director",
  credits: [ 
    "Starring: David Lowe, George Bloomfield, Helen Lowe, Lily Lowe & Lucy Jones",
    "Director: Lewis Mitchell",
    "Producer: Lewis Mitchell",
    "Co-producer: George Bloomfield & Lucy Jones",
    "In association with: Tell Studio",
    "Cinematographer: Owen Seabrook",
    "Production Designer: Aleesha Khan",
    "Music by: George Bloomfield",
    "Edited by: Lucy Jones",
    "Sound Post by: Will Cart & Sticks and Glass",
    "Picture Post by: Adam Lyons",
    "Made possible by: Youth Music and Leeds City Council"
  ],
  links: [
    { label: "IMDB", url: "https://www.imdb.com/title/tt34755384/?ref_=nm_flmg_job_1_unrel_t_1" }
  ]
};