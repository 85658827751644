import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

type HeroTransitionProps = {
  children: React.ReactNode;
};

export function HeroTransition({ children }: HeroTransitionProps) {
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{
        type: 'tween',
        duration: 0.4,
        ease: [0.4, 0, 0.2, 1]
      }}
      className="h-full"
    >
      {children}
    </motion.div>
  );
}