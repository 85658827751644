import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Project } from '../../types/project';
import { CircularArrow } from './CircularArrow';
import { FilmGrain } from '../effects/FilmGrain';

type ProjectCardProps = {
  project: Project;
};

export function ProjectCard({ project }: ProjectCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link 
      to={`/projects/${project.id}`}
      className="relative aspect-[4/3] overflow-hidden cursor-pointer block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative w-full h-full">
        <img 
          src={project.image} 
          alt={project.title}
          className="w-full h-full object-cover object-center"
        />
        <FilmGrain opacity={0.2} />
      </div>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 p-4 w-[95%]">
        <div className="relative bg-[#E4E1D7]/35 backdrop-blur-[2px] p-2 w-full rounded-sm">
          <FilmGrain opacity={0.35} />
          <div className="relative z-10 flex items-center justify-between">
            <h3 className="text-[10px] sm:text-xs md:text-sm uppercase tracking-wide text-[#2D2A25]">{project.title}</h3>
            <p className="text-[10px] sm:text-xs md:text-sm uppercase tracking-wide text-[#2D2A25]/80">{project.category}</p>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isHovered && <CircularArrow />}
      </AnimatePresence>
    </Link>
  );
}