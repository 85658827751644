export const ANIMATION_CONFIG = {
  duration: 0.5,
  ease: 'easeInOut',
} as const;

export const ROUTES = {
  HOME: '/',
  PROJECTS: '/projects',
  ABOUT: '/about',
  CONTACT: '/contact',
} as const;