import { Project } from '../../types/project';

export const marooned: Project = {
  id: 'marooned',
  title: "Marooned",
  category: "Virtual Production",
  poster: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-1X.jpg",
  image: "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-1X.jpg",
  images: [
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-2.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-3X.jpg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-4.jpeg",
    "https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/MAROONED-2023-VIRTUAL-PRODUCTION-RESEARCH-AND-DEVELOPMENT-PROJECT-VP-PRODUCTION-TELL-STUDIO-LEWIS%20MITCHELL%20-%20CREATIVE%20PRODUCER-5.jpeg"
  ],
  year: "2023",
  description: "A research and development virtual production project as a proof of concept for a sci-fi room drama. The spaceship set and 3D environment was built to create three scene snippets to give an indication of the wider story.",
  role: "Producer",
  credits: [
    "Pilot: Drew O'Brien",
    "Director: Owen Seabrook",
    "Producer: Lewis Mitchell",
    "First Assistant Director: Louisa Rose Mackleston",
    "Cinematographer: Declan Creffield",
    "3D Designer: Natascha Farmer",
    "Production Assistant: Zsolt Sandor",
    "Production Designer: Lewis Mitchell",
    "Art Department Assistant: George Bloomfield & Eliza Evans",
    "Virtual Production Facility: Xplor at Production Park"
  ],
  links: [
    { label: "Watch the teaser", url: "https://youtu.be/CgdARgJO0NU" }
  ]
};