import { useEffect } from 'react';
import { projects } from '../../data/projects';

export function useImagePreload() {
  useEffect(() => {
    const images = projects.map(project => project.image);
    images.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);
}