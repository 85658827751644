import React from 'react';
import { Link } from 'react-router-dom';
import { FooterSocialLinks } from './social/FooterSocialLinks';

type HeroProps = {
  setActiveSection: (section: string) => void;
};

export function Hero({ setActiveSection }: HeroProps) {
  return (
    <div className="page-fixed relative">
      <div
        className="absolute inset-0 bg-cover bg-center grayscale"
        style={{ 
          backgroundImage: 'url("https://raw.githubusercontent.com/lewmit/Website/refs/heads/main/LEWIS-MITCHELL-CREATIVE%20PRODUCER-IMPACT-PRODUCER-FILM-TV-COMMERCIAL-DIGITAL-UK-NEW-ZEALAND.jpg")',
        }}
      />
      <div className="absolute inset-0 bg-black/60" />
      <div className="relative z-10 h-full flex flex-col sm:flex-row items-center justify-between text-cream px-4 sm:px-8 md:px-16 lg:px-24">
        <div className="flex flex-col items-center sm:items-start w-full sm:w-[40vw] mt-[30vh] sm:mt-0">
          <h1 className="flex flex-col items-center sm:items-start w-full">
            <span className="hero-title text-4xl sm:text-5xl md:text-[6rem] lg:text-[7.5rem] font-bold text-center sm:text-left uppercase tracking-tight leading-none w-full text-white">Lewis</span>
            <span className="hero-title text-4xl sm:text-5xl md:text-[6rem] lg:text-[7.5rem] font-bold text-center sm:text-left uppercase tracking-tight leading-none w-full text-white">Mitchell</span>
          </h1>
          <p className="mt-4 text-xs sm:text-sm uppercase tracking-wider text-center sm:text-left">
            Creative Producer - Impact Producer - Film, TV, Digital
          </p>
        </div>
        <div className="flex flex-col space-y-4 mt-auto sm:mt-0 sm:ml-auto mb-24 sm:mb-0">
          <Link
            to="/projects"
            onClick={() => setActiveSection('projects')}
            className="text-xs sm:text-sm uppercase tracking-wider hover:opacity-60 transition-opacity duration-300 text-center sm:text-right"
          >
            The Work
          </Link>
          <Link
            to="/about"
            onClick={() => setActiveSection('about')}
            className="text-xs sm:text-sm uppercase tracking-wider hover:opacity-60 transition-opacity duration-300 text-center sm:text-right"
          >
            About
          </Link>
          <Link
            to="/contact"
            onClick={() => setActiveSection('contact')}
            className="text-xs sm:text-sm uppercase tracking-wider hover:opacity-60 transition-opacity duration-300 text-center sm:text-right"
          >
            Contact
          </Link>
        </div>
      </div>
      <div className="hidden sm:block">
        <FooterSocialLinks />
      </div>
    </div>
  );
}